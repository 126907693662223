<template>
  <b-form :class="isCheckSize">
    <h2 v-if="isTitle">
      <slot name="title"></slot>
    </h2>
    <p v-if="isSubtitle" :class="$style.subtitle">
      <slot name="subtitle"></slot>
    </p>
    <div class="info-block_color">
      <slot name="body"></slot>
      <div
        :class="[
          isFooterBtnLeft ? [$style.footerWrap] : $style.footerWrapRightAlign,
          { dflex: isFlexClass }
        ]"
        v-if="isFooter"
      >
        <slot name="footer">
          <BaseButton v-if="!isConnected" :disabled="!isValid" @click.stop.prevent="emitClick">
            <template #icon v-if="isButtonIcon">
              <b-icon :icon="buttonIcon" alt="Иконка 'информация'" scale="0.8"></b-icon>
            </template>
            <template #text>{{ buttonText }}</template>
          </BaseButton>

          <BaseButton v-else :disabled="!isValid" @click.stop.prevent="emitClick">
            <template #text>{{ disabledButtonText }}</template>
          </BaseButton>
          <div v-if="deleteBtn" :class="$style.deleteBtn">
            <BaseButton @click.stop.prevent="emitDeleteClick" :isNotMainColor="deleteBtn">
              <template #text>{{ deletedButtonText }}</template>
            </BaseButton>
          </div>
        </slot>
      </div>
    </div>
  </b-form>
</template>

<script>
import BaseButton from '@/components/shared/buttons/BaseButton.vue';

export default {
  props: {
    isFlexClass: {
      type: Boolean,
      require: true,
      default: true
    },
    isValid: {
      type: Boolean,
      require: true,
      default: true
    },
    isConnected: {
      type: Boolean,
      require: false,
      default: false
    },
    isFooter: {
      type: Boolean,
      require: false,
      default: true
    },
    isTitle: {
      type: Boolean,
      require: false,
      default: true
    },
    isSubtitle: {
      type: Boolean,
      require: false,
      default: false
    },
    isPageWrapper: {
      type: Boolean,
      require: false,
      default: false
    },
    buttonText: {
      type: String,
      require: false,
      default: 'Подключить'
    },
    disabledButtonText: {
      type: String,
      require: false,
      default: 'Отключить'
    },
    isButtonIcon: {
      type: Boolean,
      require: false,
      default: true
    },
    buttonIcon: {
      type: String,
      require: false,
      default: 'plug'
    },
    isFooterBtnLeft: {
      type: Boolean,
      require: false,
      default: true
    },
    deleteBtn: {
      type: Boolean,
      require: false,
      default: false
    },
    deletedButtonText: {
      type: String,
      require: false,
      default: 'Удалить'
    }
  },

  components: {
    BaseButton
  },

  computed: {
    isCheckSize() {
      if (this.isPageWrapper) return 'info-block_wrapper';
      return false;
    }
  },

  methods: {
    emitClick() {
      this.$emit('connectService');
    },

    emitDeleteClick() {
      this.$emit('deleteService');
    }
  }
};
</script>

<style module>
.subtitle {
  padding-bottom: 0.125rem;
}

.footerWrap,
.footerWrapRightAlign {
  padding-top: 1rem;
  justify-content: center;
}

@media (min-width: 992px) {
  .footerWrap {
    justify-content: flex-start;
  }

  .footerWrapRightAlign {
    justify-content: flex-end;
  }

  .deleteBtn{
    padding-top: 1rem;
  }
}
</style>
