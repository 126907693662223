<template>
  <the-wrapper :isSpinner="isSpinner">
    <ServicesDescription :isFooter="isFooter">
      <template #title> Интеграция с {{ crmDescription.title }} </template>
      <template #body>
        <div v-html="crmDescription.description"></div>
      </template>
    </ServicesDescription>
  </the-wrapper>
</template>

<script>
import TheWrapper from '@/components/shared/TheWrapper.vue';
import ServicesDescription from '@/components/shared/ServicesDescription.vue';
import { getServiceData } from '@/api/common';
import { catchFunction } from '@/additional_helpers/helpers';
import { mapMutations } from 'vuex';

export default {
  data() {
    return {
      isFooter: false,
      crmDescription: {},
      cri: this.$route.query.cri,
      isSpinner: true
    };
  },

  components: {
    ServicesDescription,
    TheWrapper
  },

  created() {
    const cri = this.cri.toLowerCase();
    getServiceData(`./json/${cri}.json`)
      .then((result) => {
        this.crmDescription = result;
        this.SHOW_LOADING(false);
      })
      .catch((e) => catchFunction(e));
  },

  methods: {
    ...mapMutations({ SHOW_LOADING: 'SHOW_LOADING' })
  }
};
</script>

<style></style>
