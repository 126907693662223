<template>
  <cti-description></cti-description>
</template>

<script>
import CtiDescription from '@/components/services/cti/CtiDescription.vue';

export default {
  components: {
    CtiDescription
  }
};
</script>

<style></style>
